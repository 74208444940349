<template>
  <div class="sci-frais">
    <!-- <div class="entete"> -->
    <div class="body-box-rapport" style="width: 100%">
      <div class="table-rapport-style">
        <div class="box-all-filter">
          <div class="box-label-champ mr-2">
            <div class="label-box-style-title">
              <span class="title-tabel">Facture SCI</span>
            </div>
          </div>
          <div class="box-label-champ mr-2" v-if="checkPermission('FSDDF')">
            <div class="label-box-style w-63-px">
              <span class="title-tabel">Date début</span>
            </div>

            <date-picker
              value-type="format"
              type="date"
              v-model="date_debut"
              :clear-button="true"
              :disabled-date="disabledStartDate"
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter"
              @input="handleDateTimePicker"
            ></date-picker>
          </div>
          <div class="box-label-champ mr-2" v-if="checkPermission('FSDDF')">
            <div class="label-box-style w-63-px">
              <span class="title-tabel">Date fin</span>
            </div>
            <date-picker
              value-type="format"
              type="date"
              v-model="date_fin"
              :clear-button="true"
              :disabled-date="disabledEndDate"
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter"
              @input="handleDateTimePicker"
            ></date-picker>
          </div>
          <filter-component
            classLabel="w-126-px"
            label="Mois"
            v-if="checkPermission('FSFAM')"
          >
            <template v-slot:body>
              <b-form-select
                class="b-form-select-new-style w-100-p mt-2 mb-2"
                v-model="filterYearMonth"
                @change="handleFilter"
                :options="[
                  { value: null, text: 'Tous' },
                  ...computedSousObjets
                ]"
                text-field="text"
                value-field="value"
              ></b-form-select
            ></template>
          </filter-component>
          <input-file-excel-num-dossier
            v-show="true"
            :value="nums"
            @searchWithNumDossier="searchWithMultipleFacture"
            :fetchFuntion="fecthFactureNumbersInExcelFile"
            :showCount="true"
          />
          <div v-if="loadingSci" class="chargement chargement-loading-icon">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="notif-export-file-sci" v-if="getShowNotifExportFile">
            Préparation du fichier en cours
          </div>
          <div class="error-message ml-2 mr-2">
            <div v-if="errorSci" class="error">
              <ul v-if="Array.isArray(errorSci)" class="mb-0">
                <li v-for="(e, index) in errorSci" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ errorSci }}</div>
            </div>
          </div>
          <div class="box-end-position">
            <search
              :value="searchValue"
              @changeSearchValue="changeSearchValue"
              v-if="checkPermission('FSR')"
            />
            <reglement
              class="mr-1"
              v-if="checkPermission('FSACSAR') && computedCheckedRowsOneClient"
              :dataToUse="computedCheckedRowsOneClientFacture"
              interfaceModal="ReglementFactureRowLibre"
            />
            <add-facture-compta-rh-sci
              :type_produit="type_produit"
              @reloadData="reloadData"
              v-if="checkPermission('FSAF')"
            />
            <!-- <facture-avoir-sci
              v-if="computedCheckedRowsAvoir.length && checkPermission('FSGFA')"
              :interfaceName="this.$options.name"
              :selected="computedCheckedRowsAvoir"
            /> -->
            <generate-avoir
              v-if="computedCheckedRowsAvoir.length && checkPermission('FSGFA')"
              :interfaceName="this.$options.name"
              :selected="computedCheckedRowsAvoir"
              @RefreshData="handleFilter"
              :functionGenerate="avoirGlobaleFactureSci"
              :famille="support"
              permission="FSACSDCA"
            />
            <transfert-facture-sci-to-libre
              v-if="
                computedCheckRowsToTransfertLibre && checkPermission('FSTFATFL')
              "
              :interfaceName="this.$options.name"
              :dataToUse="computedCheckRowsToTransfertLibre"
              :indexsToRemove="computedGetIndex"
            />
            <validate-multi-facture-sci
              v-if="
                computedCheckedRowsMultiple.length && checkPermission('FSVMF')
              "
              @reloadData="reloadData"
              :dataToUse="computedCheckedRowsMultiple"
            />
            <!-- checkPermission('FSMMSF') || -->
            <!-- <validation-multiple-statut-client
            v-if="computedCheckedRows.length && checkPermission('FSMMSCF')"
            class="mr-2"
            :dataToUse="computedCheckedRows"
            @reloadData="reloadData"
            :statutRequired="checkPermission('FSMMSF') && false"
            :statutClientRequired="checkPermission('FSMMSCF')"
          /> -->
            <!-- <b-button
            size="sm"
            class="button-export-style mr-2 ml-2"
            title="Envoyez un email aux sociéte sélectionnés"
            @click="openModal"
            v-if="
              computedGetDataToUse.length > 0 &&
              !loadingSci &&
              checkPermission('FSTHEM')
            "
          >
            <font-awesome-icon icon="envelope"
          /></b-button> -->
            <sendMail
              v-if="computedDataToSend.length > 0 && checkPermission('FSTHEM')"
              :dataToUse="computedDataToSend"
              @eventMailSendRefreshTable="handleFilter()"
            />
            <!-- <mail-modal
            :isOpen="isOpen"
            :computedGetDataToUse="computedGetDataToUse"
            @custumChange="closeModal"
            @handleSendMail="handleSendMail"
          /> -->
            <!-- <b-button
            size="sm"
            class="button-export-style ml-2"
            title="Confirmer"
            v-if="
              (computedCheck || factureChecked.length > 0) &&
                checkPermission('FSTHCLF')
            "
            @click.prevent.stop="FacuresToConfirme()"
          >
            <font-awesome-icon icon="check"
          /></b-button> -->
            <export-dynamic
              v-if="checkPermission('FSTX') || checkPermission('FSTZ')"
              :filter="computedFilter"
              :selected="computedCheckRowsExport"
              :exportXls="exportFactureLibre"
              :exportZip="downloadZIP"
              :famille="support"
              :zipRequired="checkPermission('FSTZ')"
              :xlsRequired="checkPermission('FSTX')"
            />
            <b-button
              v-if="
                checkPermission('FSFS') ||
                  checkPermission('FSFC') ||
                  checkPermission('FSFST') ||
                  checkPermission('FSFSC')
              "
              size="sm"
              class="button-export-style ml-2 mr-2"
              title="Filter"
              id="popover-target-1"
            >
              <font-awesome-icon icon="filter" />
            </b-button>
            <b-popover
              triggers="focus"
              target="popover-target-1"
              placement="top"
              custom-class="my-custom-class-popover-filter"
            >
              <filter-component
                label="Société"
                classLabel="w-100-px"
                v-if="checkPermission('FSFS')"
              >
                <template v-slot:body>
                  <multiselect
                    v-model="vendeur"
                    :multiple="true"
                    label="text"
                    @input="handleFilter"
                    :options="computedVendeurOnline"
                    :loading="getLoadingSciVendeurOnLine"
                    track-by="value"
                    class="customSelectMultipleFilter mt-2 mb-2"
                    :showLabels="false"
                    placeholder="sélectionner"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length == 1"
                        >{{ values.length }} Société</span
                      >
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length > 1"
                        >{{ values.length }} Sociétés</span
                      >
                    </template>
                  </multiselect></template
                >
              </filter-component>
              <filter-component
                label="Client"
                classLabel="w-100-px"
                v-if="checkPermission('FSFC')"
              >
                <template v-slot:body>
                  <multiselect
                    v-model="acheteur"
                    :multiple="true"
                    label="text"
                    @input="handleFilter"
                    :options="computedAcheteurOnline"
                    :loading="getLoadingSciAcheteurOnLine"
                    track-by="value"
                    class="customSelectMultipleFilter mt-2 mb-2"
                    :showLabels="false"
                    placeholder="sélectionner"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length == 1"
                        >{{ values.length }} Client</span
                      >
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length > 1"
                        >{{ values.length }} Clients</span
                      >
                    </template>
                  </multiselect></template
                >
              </filter-component>
              <filter-component
                label="Statut"
                classLabel="w-100-px
"
                v-if="checkPermission('FSFST')"
              >
                <template v-slot:body>
                  <multiselect
                    v-model="statut"
                    :options="computedValidationListFilter"
                    @input="handleFilter"
                    :multiple="true"
                    label="text"
                    track-by="value"
                    class="customSelectMultipleFilter libres mt-2 mb-2"
                    :showLabels="false"
                    placeholder="sélectionner"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length == 1"
                        >{{ values.length }} Statut</span
                      >
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length > 1"
                        >{{ values.length }} Statuts</span
                      >
                    </template>
                  </multiselect></template
                >
              </filter-component>
              <filter-component
                label="Statut client"
                classLabel="w-100-px
"
                v-if="checkPermission('FSFSC')"
              >
                <template v-slot:body>
                  <multiselect
                    v-model="statut_client"
                    :options="computedValidationListFilter"
                    @input="handleFilter"
                    :multiple="true"
                    label="text"
                    track-by="value"
                    class="customSelectMultipleFilter libres mt-2 mb-2"
                    :showLabels="false"
                    placeholder="sélectionner"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length == 1"
                        >{{ values.length }} Statut client</span
                      >
                      <span
                        :class="{
                          'multiselect__single showBlock': !isOpen,
                          'multiselect__single ': isOpen
                        }"
                        v-if="values.length > 1"
                        >{{ values.length }} Statuts clients</span
                      >
                    </template>
                  </multiselect></template
                >
              </filter-component>
            </b-popover>
          </div>
        </div>
        <!-- </div> -->
        <div>
          <div
            class="block-filter-style"
            v-if="
              date_debut != null ||
                date_fin != null ||
                searchValue != null ||
                filterYearMonth != null
            "
          >
            <span class="phrase-style">Les filtres choisie sont: </span>
            <span class="item-filter-style" v-if="date_debut !== null"
              >Date début : {{ date_debut }}</span
            >
            <span class="item-filter-style" v-if="date_fin !== null"
              >Date fin : {{ date_fin }}</span
            >
            <span v-if="searchValue !== null" class="item-filter-style"
              >Recherche : {{ searchValue }}</span
            >
            <span v-if="filterYearMonth !== null" class="item-filter-style"
              >Mois : {{ filterYearMonth }}</span
            >
          </div>
        </div>
        <div
          class="chip-filters-simulation"
          v-if="
            vendeur.length ||
              acheteur.length ||
              statut.length ||
              statut_client.length
          "
        >
          <div v-if="vendeur.length" class="block-filter">
            <span class="title-block-chip">Société : </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              v-model="vendeur"
              @input="handleFilter"
            >
              <vs-chip
                :key="v.text + 'proprietaire'"
                @click="remove(v, 'vendeur', 'handleFilter')"
                v-for="v in vendeur"
                closable
              >
                {{ v.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div v-if="acheteur.length" class="block-filter">
            <span class="title-block-chip">Client : </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="acheteur"
              @input="handleFilter"
            >
              <vs-chip
                :key="a.text + 'locataire'"
                @click="remove(a, 'acheteur', 'handleFilter')"
                v-for="a in acheteur"
                closable
              >
                {{ a.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div v-if="statut.length" class="block-filter">
            <span class="title-block-chip">Statut : </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="statut"
              @input="handleFilter"
            >
              <vs-chip
                :key="s.text + 'proprietaire'"
                @click="remove(s, 'statut', 'handleFilter')"
                v-for="s in statut"
                closable
              >
                {{ s.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div v-if="statut_client.length" class="block-filter">
            <span class="title-block-chip">Statut client : </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="statut_client"
              @input="handleFilter"
            >
              <vs-chip
                :key="s.text + 'proprietaire'"
                @click="remove(s, 'statut_client', 'handleFilter')"
                v-for="s in statut_client"
                closable
              >
                {{ s.text }}
              </vs-chip>
            </vs-chips>
          </div>
        </div>
        <!-- <div class="body-box-rapport" :style="sizeBlockTable">
      <div class="table-rapport-style w-100-p"> -->
        <b-table
          :style="sizeTable"
          show-empty
          id="my-table"
          class="custom-table-style customTableFraisSci table-header"
          :items="factureScis"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div>
              <span v-if="data.field.key != 'check_all'">
                {{ data.field.label }}</span
              >
              <div v-else>
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>

          <template v-slot:cell(details)="data">
            <button
              class="btn btn-anomalie"
              v-if="data.item.factures.length > 0"
              @click="data.toggleDetails"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(Vendeur)="data">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(Acheteur)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(sous_objet)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(num)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(numero_avoir)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(date)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(total_net_ht)="data">
            {{ data.item.total_net_ht }} €
          </template>
          <template v-slot:cell(total_tva)="data">
            {{ data.item.total_tva }} €
          </template>
          <template v-slot:cell(total_ttc)="data">
            {{ data.item.total_ttc }} €
          </template>
          <template v-slot:cell(etat)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(statut_client)="data">
            {{ data.item.vendeur ? data.item.vendeur : '-' }}
          </template>
          <template v-slot:cell(reglement)=""> - </template>
          <template v-slot:cell(historique)=""> - </template>
          <template v-slot:cell(reste)=""> - </template>
          <template v-slot:cell(date_paiement)=""> - </template>

          <template #row-details="data">
            <span
              v-for="(item, index) in data.item.factures"
              :key="data.item.id + 'mensuel' + index"
            >
              <template>
                <span class="align-td-item background-week">
                  <td class="item-details-style col-check-obligee">
                    <b-form-checkbox
                      v-model="item.check"
                      class="check-th"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </td>
                  <td class="item-details-style col-plus-obligee">
                    <span> </span>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACS')"
                  >
                    {{ data.item.name }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACC')"
                  >
                    {{ item.acheteur }}
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACMF')"
                  >
                    {{ item.sous_objet != null ? item.sous_objet : '-' }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACNF')"
                  >
                    {{ item.num }}
                    <font-awesome-icon
                      v-if="checkPermission('FSAFM')"
                      icon="eye"
                      @click="handleShowFacture(item.id)"
                    />
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACNFA')"
                  >
                    <div class="center-th-montant">
                      <font-awesome-icon
                        :id="`popover-1-avoir-${data.item.name}-${index}`"
                        v-show="
                          item.FactureAvoir && item.FactureAvoir.length != 0
                        "
                        icon="info"
                        class="
                          info-icon-commercial-lot
                          content-tooltip-style
                          w-25
                        "
                      />
                      <avoir-facture
                        v-if="
                          item.type_facture != 'Facture proforma' &&
                            item.etat != 'Annulée' &&
                            item.type !== 'separation'
                        "
                        :item="item"
                        :permission="checkPermission('FSGAP')"
                        :index="data.item.name + index"
                      />
                      <b-popover
                        :target="`popover-1-avoir-${data.item.name}-${index}`"
                        :triggers="computedPopoverTriggers"
                        custom-class="avoir-tooltip custom-z-index-popover"
                      >
                        <div v-for="(facture, i) in item.FactureAvoir" :key="i">
                          <div class="d-flex align-items-center">
                            <div class="d-inline-block">
                              {{ facture.num }} , {{ facture.total_ttc }} €

                              <font-awesome-icon
                                v-if="checkPermission('FSTFAP')"
                                icon="arrow-alt-circle-down"
                                class="
                                  icon-style-color
                                  mr-1
                                  button-export-style-avoir
                                "
                                @click="clickButton(facture.id)"
                              />
                              <font-awesome-icon
                                v-if="checkPermission('FSAFM')"
                                icon="eye"
                                @click.prevent="handleShowFacture(facture.id)"
                                title="visualiser la facture"
                                class="icon-style-color  mr-1"
                              />
                            </div>
                          </div>
                        </div>
                      </b-popover>
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACD')"
                  >
                    {{ item.date_creation | formateDate }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACMH')"
                  >
                    <EditableInput
                      :editable="
                        item.type == 'Facture proforma' &&
                          checkPermission('FSMMHT') &&
                          item.modifiable == true
                      "
                      champName="total_net_ht"
                      :item="item"
                      :value="item.total_net_ht"
                      type="text"
                      editableType="input"
                      :updateFunction="updateMontantHtFactureSci"
                      :defaultColor="true"
                      :uniteMontant="euro"
                    />
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACT')"
                  >
                    {{ item.total_tva }} €
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSTTC')"
                  >
                    <TemplateFraisSci
                      :item="item"
                      :project="item"
                      :componentName="$options.name"
                      :editable="
                        item.etat == 'Payé' ||
                        item.etat == 'Annulée' ||
                        item.modifiable == false
                          ? false
                          : checkPermission('FSVF')
                          ? true
                          : false
                      "
                      :download="item.etat == 'Annulée' ? false : item.id"
                    ></TemplateFraisSci>
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACRAP')"
                  >
                    {{ item.reste }} €
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACSFA')"
                  >
                    <EditableInput
                      :editable="
                        (checkPermission('FSSF') &&
                          item.etat != 'Payé' &&
                          item.etat != 'Annulée' &&
                          item.etat != 'Proforma annulée' &&
                          item.modifiable == true &&
                          item.type == 'Facture' &&
                          false) ||
                          (item.type == 'Facture proforma' &&
                            item.etat != 'Proforma annulée')
                      "
                      champName="etat"
                      editableType="select"
                      :optionsSelect="computedListStatut"
                      :item="item"
                      :value="item.etat"
                      :updateFunction="updateStatutFactureSci"
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACSC')"
                  >
                    <div
                      v-if="
                        (item.type == 'facture' || item.type == 'Facture') &&
                          item.etat != 'Annulée' &&
                          item.etat != 'Payé' &&
                          item.statut_client != 'Payé' &&
                          checkPermission('FSMSCF') &&
                          item.modifiable == true &&
                          false
                      "
                    >
                      <EditableInput
                        champName="statut_client"
                        editableType="select"
                        :optionsSelect="computedListStatutClient"
                        :item="item"
                        :value="item.statut_client"
                        :updateFunction="updateStatutClientFactureSci"
                        label="value"
                        :positionSelect="true"
                        formate="regieFacture"
                        :showIconEdit="true"
                      />
                    </div>
                    <div v-else>
                      {{ item.statut_client ? item.statut_client : '-' }}
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACSCDP')"
                  >
                    {{ item.date_paiement }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACSCRG')"
                  >
                    {{ item.montant_regle }} €
                    <font-awesome-icon
                      v-show="item.reglements.length"
                      :id="`popover-1-${index}`"
                      icon="info"
                      class="
                        info-icon-commercial-lot
                        content-tooltip-style
                        w-25
                      "
                    />

                    <b-popover
                      :target="`popover-1-${index}`"
                      :triggers="computedPopoverTriggers"
                      :custom-class="'avoir-tooltip ' + stylePopover"
                    >
                      <b-table-simple class="table-fiche">
                        <b-tbody>
                          <b-tr class="title-ligne">
                            <b-th class="newcolor">#</b-th>
                            <b-th class="newcolor">Montant reglé</b-th>
                            <b-th class="newcolor">Date de paiement</b-th>
                            <b-th class="newcolor">Type de paiement</b-th>
                            <b-th
                              class="newcolor"
                              v-if="checkPermission('FSACSUR')"
                            ></b-th>
                          </b-tr>
                          <template v-for="(reglement, i) in item.reglements">
                            <b-tr :key="'reglement' + i">
                              <b-td class="newcolor"
                                >{{ reglement.payment_id }}
                              </b-td>
                              <b-td class="newcolor"
                                >{{ reglement.amount }} €
                              </b-td>
                              <b-td class="newcolor">{{
                                formateDateGetters(reglement.payment_date)
                              }}</b-td>
                              <b-td class="newcolor">{{
                                reglement.payment_type
                              }}</b-td>
                              <b-td
                                class="newcolor"
                                v-if="checkPermission('FSACSUR')"
                                ><update-reglement-modal
                                  :item="reglement"
                                  dataInterface="sci"
                                  @openModal="triggersPop = 'click'"
                                  @closeModal="triggersPop = 'hover'"
                              /></b-td>
                            </b-tr>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                    </b-popover>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FSACHF')"
                  >
                    <Historique
                      :awesome="true"
                      :index="item.id"
                      :permission="checkPermission('FSACHF')"
                      :dataToUse="item.historique"
                    />
                  </td>
                </span>
              </template>
            </span>
          </template>
        </b-table>
        <b-modal
          ref="visualisationFactureModal"
          id="visualisationFactureModal"
          title="Ajouter une année"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          modal-class="cutsom-modal-bootstrap modal-dialog-visualisation"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Afficher Facture</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('visualisationFactureModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <iframe
                height="750"
                width="700"
                :src="factureToShow + '#toolbar=0'"
                scrolling="no"
              ></iframe>
            </template>
          </Card>
        </b-modal>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="TotalRowsScis"
            :per-page="per_page"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="per_page"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
          <div
            v-if="
              computedTotal &&
                (computedTotal.net_ht > 0 ||
                  computedTotal.ttc > 0 ||
                  computedTotal.someApaye > 0) &&
                checkPermission('GFMTHABT')
            "
            class="style-somme-th-ttc"
          >
            <p class="m-2">
              Total HT:
              {{   computedTotal.net_ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
              € {{ '   ' }}Total TTC:

              {{
                computedTotal.ttc
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  .replaceAll(',', ' ')
              }}
              € {{ '   ' }}
              Total à payer :
              {{
                computedTotal.someApaye
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  .replaceAll(',', ' ')
              }}
              €
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal visualisation facture -->
    <b-modal
      ref="visualisationFactureModal"
      id="visualisationFactureModal"
      title="Ajouter une année"
      :hide-footer="true"
      :hide-header="true"
      @hidden="hideModal('visualisationFactureModal')"
      modal-class="cutsom-modal-bootstrap modal-dialog-visualisation"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Afficher Facture</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('visualisationFactureModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <iframe
            height="750"
            width="700"
            :src="factureToShow + '#toolbar=0'"
            scrolling="no"
          ></iframe>
        </template>
      </Card>
    </b-modal>
  </div>
</template>
<script>
import EditableInput from '@/views/component/EditableInput.vue';

import ExportDynamic from '../ThProject/frais/fraisDynamic/component/exportDynamic.vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
// import ValidationMultipleStatutClient from '../ThProject/frais/FactureMensuel/component/ValidationMultipleStatutClient.vue';
// // import MailModal from '../factureLibre/MailModal.vue';
// import FactureAvoirSci from './component/FactureAvoirSci.vue';
import filterComponent from '../ThProject/frais/component/filterComponent.vue';
import ValidateMultiFactureSci from './component/validateMultiFactureSci.vue';

export default {
  name: 'Gestion-Invest',
  data() {
    return {
      triggersPop: 'hover',
      factureToShow: null,
      perPageList: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      page: 1,
      per_page: 50,
      vendeur: [],
      acheteur: [],
      statut_client: [],
      date_debut: null,
      date_fin: null,
      statut: [
        { value: 'A payer', text: 'A payer' },
        {
          value: 'Créé',
          text: 'Créé'
        },
        { value: 'Payé en partie', text: 'Payé en partie' }
      ],
      type_produit: 'bien',
      support: 'sci',
      column: 'id',
      sortBy: 'nom',
      searchValue: null,
      checkAll: false,
      indexSelectIsOpen: null,
      etatList: [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' }
        // { value: 'Annulée', text: 'Annulée' }
      ],
      factureChecked: [],
      factureAvoirChecked: [],
      selected: [],
      error: null,
      isOpen: false,
      filterYearMonth: null,
      euro: ' €',
      nums: null
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    }
  },
  computed: {
    ...mapGetters([
      'formateDateGetters',
      'sousObjetsMensuels',
      'getShowNotifExportFile',
      'factureScis',
      'TotalRowsScis',
      'getDataSciVendeurOnLine',
      'getLoadingSciVendeurOnLine',
      'getDataSciAcheteurOnLine',
      'getLoadingSciAcheteurOnLine',
      'loadingSci',
      'errorSci',
      'getErrorMailingFactureLibre',
      'getSuccessSendMailsFactureLibre',
      'checkPermission',
      'getUserData'
    ]),
    computedPopoverTriggers() {
      return this.triggersPop;
    },
    stylePopover() {
      if (this.computedPopoverTriggers == 'click') {
        return 'displayed-none';
      }
      return '';
    },
    computedCheckedRowsOneClient() {
      let tableReglement = [];
      let tableReglementCheck = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);
      if (this.factureScis.length) {
        this.factureScis.forEach(element => {
          element.factures.forEach(facture => {
            if (facture.check == true) {
              tableReglementCheck.push(facture);
            }
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
        return tableReglement.length == tableReglementCheck.length &&
          tableReglement.length > 0
          ? true
          : false;
      }
      return false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];

      if (this.factureScis.length) {
        let filiale_list = this.getUserData.filiales.map(item => item.id);

        this.factureScis.forEach(element => {
          element.factures.forEach(facture => {
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
      }
      return tableReglement;
    },
    computedSousObjets() {
      let sousObjets = this.sousObjetsMensuels.map(item => {
        return { value: item, text: item };
      });
      return sousObjets;
    },
    computedTotal() {
      let someHt = 0;
      let someTtc = 0;
      let someApaye = 0;

      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].factures.length; j++) {
          if (
            this.computedCheckedRows[i].factures[j].check == true &&
            this.computedCheckedRows[i].factures[j].avoir_id == null
          ) {
            someHt =
              someHt +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].factures[j].net_ht +
                      ' '.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            someTtc =
              someTtc +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].factures[j].ttc +
                      ' '.replace(' ', '').replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            if (
              this.computedCheckedRows[i].factures[j].type == 'Facture' &&
              (this.computedCheckedRows[i].factures[j].etat == 'A payer' ||
                this.computedCheckedRows[i].factures[j].etat ==
                  'Payé en partie' ||
                this.computedCheckedRows[i].factures[j].etat == 'Payé')
            ) {
              someApaye =
                someApaye +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].factures[j].reste +
                        '' +
                        ' '.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return {
        net_ht: Math.round(someHt * 100) / 100,
        ttc: Math.round(someTtc * 100) / 100,
        someApaye: Math.round(someApaye * 100) / 100
      };
    },
    computedDataToSend() {
      let selected = [];
      this.factureScis.forEach(element => {
        element.factures.forEach(facture => {
          if (
            facture.check == true &&
            facture.etat != 'Créé' &&
            facture.type != 'Facture proforma'
          ) {
            selected.push(facture);
          }
        });
      });
      return selected;
    },
    computedCheckRowsExport() {
      let selected = [];
      this.factureScis.forEach(element => {
        element.factures.forEach(facture => {
          if (facture.check == true) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedCheckedRowsAvoir() {
      let tableF = this.factureScis?.filter(f => {
        return f.factures.some(
          e =>
            e.check == true &&
            e.type == 'Facture' &&
            e.avoir_id == null &&
            e.modifiable == true
        );
      });
      return tableF;
    },
    computedVendeurOnline() {
      let vendeurNames = this.getDataSciVendeurOnLine.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedAcheteurOnline() {
      let acheteurNames = this.getDataSciAcheteurOnLine.map(item => {
        return { value: item.id, text: item.name };
      });
      return acheteurNames;
    },
    computedListStatutClient() {
      return [
        { value: 'A payer', text: 'à payer' },
        { value: 'Payé', text: 'Payée' }
      ];
    },
    computedListStatut() {
      return [
        { value: 'A payer', text: 'Facture à payer', $isDisabled: true },
        { value: 'Payé', text: 'Facture payée', $isDisabled: true },
        { value: 'Proforma annulée', text: 'Proforma annulée' }
      ];
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 224 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 133 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedGetIndex() {
      let arrayOfIndex = [];
      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].factures.length; j++) {
          if (
            this.computedCheckedRows[i].factures[j].check == true &&
            this.computedCheckedRows[i].factures[j].avoir_id !== null
          ) {
            arrayOfIndex.push({
              indexOfChild: this.computedCheckedRows[i].factures[j].id,
              indexOfFather: this.computedCheckedRows[i].name
            });
          }
        }
      }
      return arrayOfIndex;
    },
    computedCheckedRowsMultiple() {
      let tableF = this.factureScis?.filter(f => {
        return f.factures.some(
          e =>
            e.check == true &&
            e.type == 'Facture proforma' &&
            e.modifiable == true
        );
      });
      return tableF;
    },
    computedCheckRowsToTransfertLibre() {
      let selected = [];
      this.factureScis.forEach(element => {
        element.factures.forEach(facture => {
          if (
            facture.check == true &&
            facture.avoir_id !== null &&
            facture.etat == 'Annulée' &&
            facture.modifiable == true
          ) {
            selected.push(facture.id);
          }
        });
      });
      return selected;
    },
    computedCheckChipFilter() {
      if (
        this.vendeur.length > 0 ||
        this.acheteur.length > 0 ||
        this.statut.length > 0 ||
        this.statut_client.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedCheckSelected() {
      let selected = [];
      this.factureScis?.forEach(element => {
        if (element.check == true) {
          selected.push(element.id);
        }
      });
      return selected;
    },
    computedFilter() {
      return {
        search: this.searchValue,
        etat: this.statut,
        vendeur: this.vendeur,
        acheteur: this.acheteur,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        col: this.column,
        famille: this.support
      };
    },
    computedCheckedRows() {
      let tableF = this.factureScis?.filter(f => {
        return f.factures.some(e => e.check == true);
      });
      return tableF;
    },
    computedValidationList() {
      return [
        { value: 'Créé', text: 'Créé' },
        { value: 'A payer ', text: 'A payer ' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ];
    },
    computedValidationListFilter() {
      return [
        { value: 'Créé', text: 'Créé' },
        { value: 'A payer', text: 'A payer ' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ];
    },
    computedFraisBddCommericalCactus() {
      return this.factureScis;
    },
    computedFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true
        },
        {
          key: 'details',
          label: '',
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          show: true
        },
        {
          key: 'Vendeur',
          label: 'Societé',
          show: this.checkPermission('FSACS')
        },
        {
          key: 'Acheteur',
          label: 'Client',
          show: this.checkPermission('FSACC')
        },
        {
          key: 'sous_objet',
          label: 'Mois',
          show: this.checkPermission('FSACMF')
        },
        {
          key: 'num',
          label: 'N° Facture',
          show: this.checkPermission('FSACNF')
        },
        {
          key: 'numero_avoir',
          label: 'Numéro de facture avoir',
          show: this.checkPermission('FSACNFA')
        },
        {
          key: 'date',
          label: 'Date',
          show: this.checkPermission('FSACD')
        },
        {
          key: 'total_net_ht',
          label: 'Montant HT',
          show: this.checkPermission('FSACMH')
        },
        {
          key: 'total_tva',
          label: 'TVA',
          show: this.checkPermission('FSACT')
        },
        ,
        {
          key: 'total_ttc',
          label: 'Montant TTC',
          show: this.checkPermission('FSTTC')
        },
        {
          key: 'reste',
          label: 'Reste à payer',
          show: this.checkPermission('FSACRAP')
        },
        {
          key: 'etat',
          label: 'Statut',
          show: this.checkPermission('FSACSFA')
        },

        {
          key: 'statut_client',
          label: 'Statut client',
          show: this.checkPermission('FSACSC')
        },
        {
          key: 'date_paiement',
          label: 'Date prévisionnelle du paiement',
          show: this.checkPermission('FSACSCDP')
        },
        {
          key: 'reglement',
          label: 'Reglement',
          show: this.checkPermission('FSACSCRG')
        },
        {
          key: 'historique',
          label: 'Historique',
          show: this.checkPermission('FSACHF')
        }
      ];
      return fields.filter(item => item.show == true);
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data != 'check_all' &&
          data != 'vendeur' &&
          data != 'acheteur' &&
          data != 'type' &&
          data != 'date_creation' &&
          data != 'filiale_associer'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedWidth() {
      return function(data) {
        if (data && data.etat && data.etat == 'Payé en partie') {
          let montant = (data.montant_payer * 100) / data.total_ttc;
          if (montant > 100) {
            montant = 100;
          }
          return { width: montant + '%' };
        } else {
          return { width: '0%' };
        }
      };
    },
    computedCheck() {
      let checkesRows = this?.factureScis?.filter(row => row.check == true);
      if (checkesRows.length > 0) {
        return true;
      }
      return false;
    },
    computedGetDataToUse() {
      let checkesRows = this?.factureScis?.filter(
        row => row.check === true && row.type !== "Facture d'avoir"
      );
      return checkesRows;
    }
  },
  methods: {
    ...mapActions([
      'getSousObjets',
      'updateStatutFactureSci',
      'updateStatutClientFactureSci',
      'updateMontantHtFactureSci',
      'displayFact',
      'exportFactureLibre',
      'getdataSciVendeurOnLine',
      'getdataSciAcheteurOnLine',
      'getFacturesScis',
      'downloadZIP',
      'sendMailFactureLibre',
      'avoirGlobaleFactureSci',
      'fecthFactureNumbersInExcelFile',
      'exportPdfFactureSci',
      'displayFact'
    ]),
    async handleShowFacture(id) {
      this.$refs['visualisationFactureModal'].show();
      const response = await this.displayFact(id);
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    clickButton(id) {
      this.exportPdfFactureSci({
        id: id
      });
    },
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },

    handleChangeMonth() {
      this.setLocalStorageFactureSci();
      this.handleFilter();
    },
    async handleChangeYear() {
      this.page = 1;
      this.filterMonth = null;
      this.fetchMonthForYearTH(this.year);
      this.setLocalStorageFactureSci();
      this.handleFilter();
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == true && this.checkAll == false) {
        this.checkAll = true;
      }
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.vendeur = [];
      this.acheteur = [];
      this.dataSciOnLine();
      this.handleFilter();
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },

    dataSciOnLine() {
      this.getdataSciVendeurOnLine({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      this.getdataSciAcheteurOnLine({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
    },
    handleFilter() {
      this.checkAll = false;
      this.setLocalStorageFactureSci();
      this.getFacturesScis({
        search: this.searchValue,
        etat: this.statut,
        vendeur_id: this.vendeur,
        acheteur_id: this.acheteur,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        page: this.page,
        per_page: this.per_page,
        order: 'DESC',
        column: 'id',
        famille: 'sci',
        mois: this.filterYearMonth,
        statut_client: this.statut_client,
        nums: this.nums
      });
    },
    reloadData() {
      this.checkAll = false;
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return 'ligneEnCourDeValidation';
    },
    checkAllFunction() {
      this.factureScis.map(item => {
        item.check = this.checkAll;
        item.factures.map(facture => {
          facture.check = this.checkAll;
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.factures.map(facture => {
        facture.check = item.check;
      });
    },
    setLocalStorageFactureSci() {
      localStorage.setItem(
        'facture-sci',
        JSON.stringify({
          search: this.searchValue,
          statut: this.statut,
          statut_client: this.statut_client,
          vendeur: this.vendeur,
          acheteur: this.acheteur,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filterYearMonth: this.filterYearMonth
        })
      );
    },
    // async handleSendMail(selectedAcheteurVendeur) {
    //   this.$bvModal.hide('mailModal');
    //   var bodyFormData1 = new FormData();
    //   for (let i = 0; i < this.computedGetDataToUse.length; i++) {
    //     bodyFormData1.append(
    //       'ids[' + [i] + ']',
    //       this.computedGetDataToUse[i].id
    //     );
    //   }
    //   bodyFormData1.append('parameter', 'Acheteur');
    //   var bodyFormData2 = new FormData();
    //   for (let i = 0; i <script i++) {
    //     bodyFormData2.append(
    //       'ids[' + [i] + ']',
    //       this.computedGetDataToUse[i].id
    //     );
    //   }
    //   bodyFormData2.append('parameter', 'Vendeur');
    //   if (selectedAcheteurVendeur.includes('acheteur')) {
    //     const response = await this.sendMailFactureLibre(bodyFormData1);
    //     if (response.succes) {
    //       this?.getErrorMailingFactureLibre?.map((element) => {
    //         if (element.societe.length > 0) {
    //           this.flashMessage.show({
    //             status: 'error',
    //             title: element.message,
    //             clickable: true,
    //             time: 0,
    //             message: element.societe,
    //           });
    //         }
    //       });
    //       if (this.getSuccessSendMailsFactureLibre?.societe?.length > 0) {
    //         this.flashMessage.show({
    //           status: 'success',
    //           title: this.getSuccessSendMailsFactureLibre?.message,
    //           clickable: true,
    //           time: 0,
    //           message: this.getSuccessSendMailsFactureLibre?.societe,
    //         });
    //       }
    //     } else {
    //       this.flashMessage.show({
    //         status: 'error',
    //         title: 'ERROR',
    //         clickable: true,
    //         time: 0,
    //         message: this.getOtherErrorMailFactureLibre,
    //       });
    //     }
    //   }
    //   if (selectedAcheteurVendeur.includes('vendeur')) {
    //     const response2 = await this.sendMailFactureLibre(bodyFormData2);
    //     if (response2.succes) {
    //       this?.getErrorMailingFactureLibre?.map((element) => {
    //         if (element.societe.length > 0) {
    //           this.flashMessage.show({
    //             status: 'error',
    //             title: element.message,
    //             clickable: true,
    //             time: 0,
    //             message: element.societe,
    //           });
    //         }
    //       });
    //       if (this.getSuccessSendMailsFactureLibre?.societe?.length > 0) {
    //         this.flashMessage.show({
    //           status: 'success',
    //           title: this.getSuccessSendMailsFactureLibre?.message,
    //           clickable: true,
    //           time: 0,
    //           message: this.getSuccessSendMailsFactureLibre?.societe,
    //         });
    //       }
    //     } else {
    //       this.flashMessage.show({
    //         status: 'error',
    //         title: 'ERROR',
    //         clickable: true,
    //         time: 0,
    //         message: this.getOtherErrorMailFactureLibre,
    //       });
    //     }
    //   }
    // },
    // openModal() {
    //   this.isOpen = true;
    // },
    // closeModal() {
    //   this.isOpen = false;
    // },
    hideModal(ref) {
      this.$refs[ref].hide();
    }
  },
  components: {
    filterComponent,
    InputFileExcelNumDossier: () =>
      import('../ThProject/componentRapportTh/inputFileExcelNumDossier.vue'),
    TransfertFactureSciToLibre: () =>
      import('./component/transfertFactureSciToLibre.vue'),
    Card: () => import('@/views/component/card.vue'),
    TemplateFraisSci: () => import('./component/TemplateFraisSci.vue'),
    EditableInput,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    AddFactureComptaRhSci: () =>
      import(
        '../ThProject/frais/FactureMensuel/component/addFactureComptaRhSci.vue'
      ),
    sendMail: () => import('@/views/component/mail/sendMail.vue'),
    // MailModal,
    ExportDynamic,
    // FactureAvoirSci,
    ValidateMultiFactureSci,
    // ValidationMultipleStatutClient,
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    updateReglementModal: () =>
      import('@/views/component/reglement/updateReglementModal.vue'),
    Historique: () => import('@/views/component/Historique.vue'),
    GenerateAvoir: () => import('@/views/component/avoir/GenerateAvoir.vue'),
    avoirFacture: () =>
      import('../ThProject/frais/fraisDynamic/component/avoirFacture.vue')
  },
  async mounted() {
    this.getSousObjets({
      date_debut: this.date_debut,
      date_fin: this.date_fin,
      famille: 'sci'
    });
    if (localStorage.getItem('facture-sci')) {
      var localStor = JSON.parse(localStorage.getItem('facture-sci'));
      this.filterYearMonth = localStor.filterYearMonth;
      this.searchValue = localStor.search;
      this.statut = localStor.statut1 ? localStor.statut : [];
      this.statut_client = localStor.statut_client
        ? localStor.statut_client
        : [];
      this.acheteur = localStor.acheteur;
      this.vendeur = localStor.vendeur;
      this.date_debut = localStor.date_debut;
      this.date_fin = localStor.date_fin;
    } else {
      this.setLocalStorageFactureSci();
    }
    this.dataSciOnLine();
    this.handleFilter();
  },
  directives: {
    ClickOutside
  }
};
</script>
<style scoped lang="scss">
.custom-z-index-popover {
  z-index: 500;
}
.sci-frais {
  padding-top: 20px;
  padding-left: 15px;
  width: 99%;
  .entete {
    padding: 5px 10px;
    width: 99%;
  }
  .body-box-rapport {
    height: calc(100vh - 81px);
    .customTableFraisSci {
      margin-bottom: 0px;
    }
    .style-somme-th-ttc {
      font-family: 'Montserrat', sans-serif;
      background-color: #d0e4f5;
      position: absolute;
      left: 1px;
      bottom: 0px;
      width: auto;
      height: 35px;
      border-radius: 30px;
      z-index: 120;
    }
  }
  .table-rapport-style {
    width: 100% !important;
  }
}
</style>
<style lang="scss">
.table-rapport-style .customTableFraisSci {
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    width: 135px;
    min-width: 135px;
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    vertical-align: middle;
    td {
      width: 135px;
      min-width: 135px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}

.displayed-none {
  display: none !important;
}
</style>
